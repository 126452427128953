<template>
	<div class="container" style="min-height: 100vh;padding-bottom: 1rem;">
		<div class="header">下载中心</div>
		<div class="blian22"></div>
		<!-- 筛选 -->
		<div class="search-tab">
			<dl>
				<dt>学段：</dt>
				<dd>
					<span :class="learnSection == item.tagId ? 'active' : ''" v-for="(item,index) in learnSectionArr" :key="index" @click="changeTab(1,item.tagId)">{{item.tagName}}</span>
				</dd>
			</dl>
			<dl v-if="learnSection">
				<dt>年级：</dt>
				<dd>
					<span :class="grade == item.tagId ? 'active' : ''" v-for="(item,index) in gradeArr" :key="index" @click="changeTab(2,item.tagId)">{{item.tagName}}</span>
				</dd>
			</dl>
			<dl v-if="grade">
				<dt>科目：</dt>
				<dd>
					<span :class="subject == item.tagId ? 'active' : ''" v-for="(item,index) in subjectArr" :key="index" @click="changeTab(3,item.tagId)">{{item.tagName}}</span>
				</dd>
			</dl>
			<dl>
				<dt>版本：</dt>
				<dd>
					<span :class="edition == item.tagId ? 'active' : ''" v-for="(item,index) in editionArr" :key="index" @click="changeTab(4,item.tagId)">{{item.tagName}}</span>
				</dd>
			</dl>
			<dl>
				<dt>册别：</dt>
				<dd>
					<span :class="volumes == item.tagId ? 'active' : ''" v-for="(item,index) in volumesArr" :key="index" @click="changeTab(5,item.tagId)">{{item.tagName}}</span>
				</dd>
			</dl>
			<dl>
				<dt>资源类型：</dt>
				<dd>
					<span :class="downloadType == item.tagId ? 'active' : ''" v-for="(item,index) in downloadIdArr" :key="index" @click="changeTab(8,item.tagId)">{{item.tagName}}</span>
				</dd>
			</dl>
		</div>
		<!--  -->
		<div class="down-list">
			<ul>
				<li v-for="(item,index) in zipArr" :key="index" @click="goDownCont(item)" >
					<img src="../assets/images/zip.png" alt="" class="zip">
					<span class="ellipsis_one" style="width: 77%;">{{item.resourceName}}</span>
					<img src="../assets/images/down_big.png" alt="" class="down" >
				</li>
				<div class="vertical" style="position: absolute;font-size: 0.7rem;" v-if="zipArr.length<=0">
					暂无内容...
				</div>
			</ul>
		</div>
	</div>
</template>
<script>
	import { tags,downloadResource } from "@/assets/js/api";
	import { Indicator } from 'mint-ui';//加载样式
	export default {
		name: "down",
		data() {
			return {
				learnSectionArr: [], //学段
				gradeArr: [], //年级
				editionArr: [], //版本
				volumesArr: [], //册别
				subjectArr: [], //课程
				downloadIdArr:[],//资源类型
				
				edition: '',
				grade: '',
				learnSection: '',
				volumes: '',
				subject: '',
				downloadType:'',
				zipArr: []
			};
		},
		methods: {
			changeTab: function(type, index) {
				if(type == 1) {
					this.learnSection = this.learnSection==index?'':index;
					this.getTags(2,this.learnSection);
					this.grade = '';
					this.subject = '';
				} else if(type == 2) {
					this.grade = this.grade==index?'':index;
					this.getTags(3,this.grade);
					this.subject = '';
				} else if(type == 3) {
					this.subject = this.subject==index?'':index;
				} else if(type == 4) {
					this.edition = this.edition==index?'':index;
				} else if(type == 5) {
					this.volumes = this.volumes==index?'':index;
				} else if(type == 8) {
					this.downloadType = this.downloadType==index?'':index;
				}
				this.getDownloadResource()
			},
			goDownCont: function(item) {
				this.$router.push({
					path: "./unitsummaryZip",
					query: {
						titleName: item.resourceName,
						path:item.linkUrl
					}
				});
			},
			getTags(tagType,upperTagId) {
				tags({
					upperTagId:upperTagId,
					tagType: tagType
				}).then(res => {
					if(res.code == 0) {
						if(tagType == 1) {
							this.learnSectionArr = res.tags;
						} else if(tagType == 2) {
							this.gradeArr = res.tags;
						} else if(tagType == 3) {
							this.subjectArr = res.tags;
						} else if(tagType == 4) {
							this.editionArr = res.tags;
						} else if(tagType == 5) {
							this.volumesArr = res.tags;
						} else if(tagType == 8) {
							this.downloadIdArr = res.tags;
						}
						
					}
				});
			},
			//获取资源下载内容
			getDownloadResource(){
				Indicator.open()
				let params = {
					grade:this.grade,
					volumes:this.volumes,
					subject:this.subject,
					edition:this.edition,
					learnSection:this.learnSection,
					downloadType:this.downloadType
				}
				downloadResource(params).then(res => {
					if(res.code == 0) {
						this.zipArr = res.downloadList;
						Indicator.close()
					}else {
						Indicator.close()
					}
				});
			}
		},
		mounted() {
			//		1:学段， 2：年级， 3：科目， 4：版本， 5：册别 6:试卷类型, 7:地区
			this.getTags(1);
			this.getTags(4);
			this.getTags(5);
			this.getTags(8);
			this.getDownloadResource();
		}
	};
</script>